// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

$link: rgba(0, 0, 0, 0.5);
$link-hover: rgba(0, 0, 0, 0.7);

$spacing: 15px;
$borderRadius: 5px;
$borderRadius-nav: 5px;

//compatibility
$lightblue: $primary !default;
