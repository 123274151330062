/** 
 * version 2
 * @release development
*/
//color

$color-white: white;

$epp-main-background: #fff;

$epp-scrollbar: #ccc;

$epp-gradient-two-primary: #353512;
$epp-gradient-two-secondary: #f7f7f5;

$epp-color-gray: #eaeaea;

$epp-modal-background: rgba(0, 0, 0, 0.6);
$epp-color-p: rgba(
    $color: var(--epp-color-font),
    $alpha: 0.7,
);

$epp-primary-btn-hover: $primary-75;
$epp-headline-color: var(--epp-color-font);

$custom-cookie-icon-color: $primary;
$custom-cookie-icon-before: white;

$custom-checkbox-color: $primary;

@mixin customHeadlineColor($color: false) {
    @if ($color == false) {
        color: #7d7d7d;
    } @else {
        color: $color;
    }
}

@mixin customCookieIconColor($color: false) {
    @if ($color == '') {
        background-color: $primary-75;
    } @else {
        background-color: $color;
    }

    @if ($color == white) {
        @if ($custom-cookie-icon-before != '') {
            &::before {
                color: $custom-cookie-icon-before !important;
            }
        } @else if($custom-cookie-icon-before == '') {
            &::before {
                color: $primary !important;
            }
        } @else {
            &::before {
                color: #333 !important;
            }
        }
    } @else {
        @if ($custom-cookie-icon-before != '') {
            &::before {
                color: $custom-cookie-icon-before !important;
            }
        }
    }
}

@mixin customCheckboxColor($color: false) {
    @if ($color == '') {
        background-color: var(--epp-color-checkbox);
        border-color: var(--epp-color-checkbox);
    } @else {
        background-color: $color;
        border-color: $color;
    }
}
