* {
    font-family: 'Nunito', sans-serif;
}

a {
    color: black;
    text-decoration: none;
    text-transform: none;

    &:active,
    &:focus,
    &:hover {
        text-decoration: none;
        text-transform: none;
    }
}

table.dataTable > thead .sorting:before,
table.dataTable > thead .sorting:after,
table.dataTable > thead .sorting_asc:before,
table.dataTable > thead .sorting_asc:after,
table.dataTable > thead .sorting_desc:before,
table.dataTable > thead .sorting_desc:after,
table.dataTable > thead .sorting_asc_disabled:before,
table.dataTable > thead .sorting_asc_disabled:after,
table.dataTable > thead .sorting_desc_disabled:before,
table.dataTable > thead .sorting_desc_disabled:after {
    bottom: 6px;
}

th {
    background-color: $gray-25;
    padding: 8px 10px;

    &:first-of-type {
        border-radius: 5px 0 0 5px;
    }

    &:last-of-type {
        border-radius: 0 5px 5px 0;
    }
}

tbody {
    tr {
        &:first-of-type {
            td {
                // padding-top: $spacing;
            }
        }
    }
}

@include media-breakpoint-down(md) {
    h1 {
        font-size: 30px;
    }

    h2 {
        font-size: 25px;
    }
}

a {
    &.link-blue {
        color: $primary;
        transition: color 200ms ease-in-out;
        &:hover {
            color: darken($primary, 15%);
        }
    }
}
