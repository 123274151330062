.header {
    padding-top: 10px;

    &.header--sticky {
        position: sticky;
        top: 0;

        background: white;
        z-index: 1002;

        margin-bottom: 30px;

        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.02), 0 2px 2px rgba(0, 0, 0, 0.02), 0 4px 4px rgba(0, 0, 0, 0.02), 0 8px 8px rgba(0, 0, 0, 0.02),
            0 16px 16px rgba(0, 0, 0, 0.02), 0 32px 32px rgba(0, 0, 0, 0.02);
    }
}
