/** 
 * version 2
 * @release development
*/

//epo-mixins
/* primary gradient -  if hover: false; hover is disabled  */
@mixin epp-primary-color($direction) {
    background: var(--epp-color-primary);
    background: -webkit-linear-gradient(to $direction, var(--epp-color-primary), var(--epp-color-secondary)); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
        to $direction,
        var(--epp-color-primary),
        var(--epp-color-secondary)
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
@mixin epp-primary-color-image($direction, $image) {
    background: var(--epp-color-primary) $image;
    background-image: $image, -webkit-linear-gradient(to $direction, var(--epp-color-primary), var(--epp-color-secondary)); /* Chrome 10-25, Safari 5.1-6 */
    background-image: $image, linear-gradient(to $direction, var(--epp-color-primary), var(--epp-color-secondary)); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
/* secondary gradient -  if hover: false; hover is disabled  */
@mixin epp-secondary-color($direction) {
    background: $epp-gradient-two-primary; /* fallback for old browsers */
    background: -webkit-linear-gradient(to $direction, $epp-gradient-two-primary, $epp-gradient-two-secondary); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
        to $direction,
        $epp-gradient-two-primary,
        $epp-gradient-two-secondary
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

@mixin epp-white-color($direction) {
    background: white; /* fallback for old browsers */
    background: -webkit-linear-gradient(to $direction, white, white); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to $direction, white, white); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

/* epp-round mixin creates a round div */
@mixin epp-round($size) {
    width: $size;
    height: $size;
    border-radius: $size;
}
/* flex center - if center: true; item is vertical centered  */
@mixin epp-flex-center($center: false) {
    display: flex;
    justify-content: center;
    @if $center {
        align-items: center;
    }
}
/* tansition mixin */
@mixin epp-transition($element, $time, $animation) {
    transition: $element $time $animation;
}

@mixin btn-primary() {
    // font-size: 0.875rem !important;
    font-size: 1rem !important;
    // padding: 1.25rem 1.25rem 1.1875rem;
    background-color: $primary;
    text-align: center;
    user-select: none;
    background-image: none;
    cursor: pointer;
    display: inline-block;
    margin-bottom: 0;
    position: relative;
    text-decoration: none;
    touch-action: manipulation;
    transform: perspective(1px) translateZ(0);
    transition: color 0.3s ease-in;
    vertical-align: middle;
    width: 100%;
    transition: background-color 300ms ease-in-out;
    border: 2px solid transparent;
    font-weight: 300 !important;
    color: white;
    &::before {
        background-color: $primary-75;
        bottom: 0;
        content: '';
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        transform: scaleX(0);
        transform-origin: 0 50%;
        transition-duration: 0.3s;
        transition-property: -webkit-transform;
        transition-property: transform;
        transition-property: background-color;
        transition-property: transform, -webkit-transform;
        -webkit-transition-timing-function: ease-out;
        transition-timing-function: ease-out;
        z-index: -1;
    }
    &:hover {
        background-color: $primary-75;
        // border: 2px solid var(--epp-color-primary-hover);
        // color: var(--epp-color-primary-hover);
        color: white;
    }
}
@mixin btn-white() {
    @include epp-white-color(right);
    @include epp-transition(border-radius, 0.4s, ease-in-out);
    z-index: 100;
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        opacity: 0;
        @include epp-white-color(left);
        @include epp-transition(opacity, 0.6s, ease);
        z-index: -100;
    }
    &:hover {
        border-radius: var(--epp-var-borderRadius)-hover;
        &::before {
            opacity: 1;
        }
    }
}
@mixin btn-secondary() {
    font-size: 1rem !important;
    background-color: $gray-50;
    text-align: center;
    user-select: none;
    background-image: none;
    border: 0 none;
    cursor: pointer;
    display: inline-block;
    margin-bottom: 0;
    position: relative;
    text-decoration: none;
    touch-action: manipulation;
    transform: perspective(1px) translateZ(0);
    -webkit-transition: color 0.3s ease-in;
    transition: color 0.3s ease-in;
    vertical-align: middle;
    width: 100%;
    transition: background-color 300ms ease-in-out;
    color: white;

    font-weight: 300 !important;
    &::before {
        background-color: $gray-25;
        bottom: 0;
        content: '';
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
        -webkit-transform-origin: 0 50%;
        transform-origin: 0 50%;
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        -webkit-transition-property: -webkit-transform;
        transition-property: -webkit-transform;
        transition-property: transform;
        transition-property: transform, -webkit-transform;
        -webkit-transition-timing-function: ease-out;
        transition-timing-function: ease-out;
        z-index: -1;
    }
    &:hover {
        background-color: $gray-25;
        color: white !important;
    }
}

@mixin customer-font($fontFamily, $fontWeight, $fontSize, $lineHeight) {
    font-family: $fontFamily, sans-serif;
    font-weight: $fontWeight;
    font-size: $fontSize;
    line-height: $lineHeight;
}
