.filter {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 30px;

    flex-wrap: wrap;

    .filter__content {
        display: flex;
        flex-direction: column;

        // .filter__item {
        //     margin-right: $spacing - 5px;

        //     &:last-of-type {
        //         margin-right: 0;
        //     }
        // }
    }

    .filter__item {
        background-color: $gray-25;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 8px 10px;
        border-radius: 5px;

        margin-bottom: 10px;

        label {
            margin: 0;
            margin-right: 10px;

            opacity: 0.6;
        }

        select {
            background: transparent;
            border: 0;
        }
    }

    .filter__actions {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        //min-width: 300px;

        a {
            margin-right: 30px;
        }
    }

    @include media-breakpoint-up(lg) {
        flex-direction: row;

        .filter__content {
            flex-direction: row;

            .filter__item {
                margin-right: $spacing - 5px;

                &:last-of-type {
                    margin-right: 0;
                }
            }
        }

        .filter__item {
            margin-bottom: 0;

            flex-direction: column;
            label {
                margin-right: 0;
            }
        }

        .filter__actions {
            min-width: 300px;
        }
    }

    @include media-breakpoint-up(xl) {
        .filter__item {
            justify-content: flex-start;

            flex-direction: row;
            label {
                margin-right: 10px;
            }
        }

        .filter__actions {
            min-width: auto;
        }
    }
}

div#DataTables_Table_0_wrapper {
    width: 100% !important;
}
