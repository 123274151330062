.footer {
    display: flex;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.03);
    padding-top: 15px;
    padding-bottom: 15px;

    .footer__content {
        flex-direction: column;
        justify-content: space-between;

        .footer__left,
        .footer__right {
            text-align: center;
        }

        .footer__right {
            margin-top: 15px;
        }
    }

    a {
        display: inline-flex;
        width: auto;
        padding: 0 10px 0 0;

        color: $link;
        text-transform: none;
        text-decoration: none;

        transition: all 200ms ease-in-out;
        font-size: 14px;

        &:hover,
        &:focus,
        &:active {
            color: $primary;
        }

        &:last-of-type {
            padding: 0;
        }
    }

    @include media-breakpoint-up(lg) {
        padding-top: 30px;
        padding-bottom: 30px;

        .footer__content {
            flex-direction: row;

            .footer__left,
            .footer__right {
                text-align: left;
            }

            .footer__right {
                margin-top: 0;
            }
        }

        a {
            padding: 0 20px 0 0;
        }
    }
}
