.searchbar {
    //position: sticky;
    position: relative;
    // top: 212px;
    width: 100%;
    height: 80px;

    margin-top: -30px;
    z-index: 100;

    .searchbar__container {
        display: flex;
        align-items: center;
        position: relative;
        background: #f7f7f7;
        width: calc(100vw - #{$spacing});
        height: 80px;
        top: 0;

        @include centerElement();
    }

    .searchbar__content {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        h1,
        h2 {
            display: none;
            align-items: center;
            margin-bottom: 5px;

            font-size: 18px;
        }
        form {
            display: inline-flex;
            flex-direction: row;
            align-items: center;
            width: 100%;

            input {
                border: 0;
                outline: 0;
            }

            button {
                margin-left: $spacing;
            }
        }
    }

    @include media-breakpoint-up(md) {
        height: 100px;

        .searchbar__container {
            height: 100px;
        }

        .searchbar__content {
            flex-wrap: nowrap;
            h1,
            h2 {
                display: inline-flex;
                margin-bottom: 0;
                font-size: 25px;
            }
            form {
                width: 60%;
            }
        }
    }
}

.typeahead {
    &.dropdown-menu {
        z-index: 3;
    }
}

.searchbar-suggestions {
    border-radius: 5px;
    background-color: white;
    transform: translate(0, 10px);
    max-height: 415px;
    overflow-y: scroll;

    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }

    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.07), 0 4px 8px rgba(0, 0, 0, 0.07), 0 8px 16px rgba(0, 0, 0, 0.07),
        0 16px 32px rgba(0, 0, 0, 0.07), 0 32px 64px rgba(0, 0, 0, 0.07);

    ul {
        margin: 0;
        padding: 0 0;
        list-style: none;

        li {
            cursor: pointer;
            display: flex;
            background-color: #f2f2f2;
            padding: 10px 20px;

            transition: all 200ms ease-in-out;

            &:nth-child(odd) {
                background-color: #f2f2f2;
            }

            &:nth-child(even) {
                background-color: #fff;
            }

            &:hover {
                color: #007fbe;
            }
        }
    }
}
