$fallback: #f2f2f2;

$primary: #007fbe;
$primary-75: #4c95cd;
$primary-50: #94b6de;
$primary-25: #ccdaf0;

$gray: #97999b;
$gray-75: #b9b8bb;
$gray-50: #cfced0;
$gray-25: #e6e6e6;

// Colors
$blue: $primary;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;
