.mainnavigation {
    background: white;

    .container {
        position: relative;
    }

    .navbar-brand {
        position: relative;

        img {
            height: 60px;
        }

        span {
            position: absolute;
            bottom: 0;
            margin-left: 10px;
            opacity: 0.5;
            font-size: 15px;
        }
    }

    .navbar-collapse {
        background: $fallback;
        display: flex;
        position: absolute;
        right: 15px;
        top: 85px;
        border-radius: 5px;
        transition: all 300ms ease-in-out 0s;

        z-index: 1002;

        @include media-breakpoint-down(md) {
            &.collapse:not(.show) {
                display: none !important;
            }
        }
    }

    ul {
        &.navbar-nav {
            li {
                &.nav-item {
                    display: flex;
                    justify-content: flex-end;

                    padding: 5px 20px;
                    min-width: 150px;

                    a {
                        transition: all 300ms ease-in-out;
                    }

                    &:hover,
                    &:focus,
                    &:active {
                        a {
                            color: $primary;
                        }
                    }
                }
            }
        }
    }

    .navbar-toggler {
        border: 0;

        &:hover,
        &:focus,
        &:active {
            border: 0;
            outline: none;
            box-shadow: none;
        }
    }

    @include media-breakpoint-up(lg) {
        .navbar-collapse {
            background: transparent;
            top: 15px;
        }

        ul {
            &.navbar-nav {
                li {
                    &.nav-item {
                        padding: 0 10px;

                        justify-content: inherit;
                        min-width: inherit;

                        &:last-of-type {
                            padding-right: 0;
                        }

                        &:nth-of-type(1) {
                            margin-right: 100px;
                        }

                        i {
                            margin-right: 5px;
                            opacity: 0.6;
                        }
                    }
                }
            }
        }
    }
}

.navbar-playbutton {
    position: absolute;
    left: 270px;
}

.navbar-playbutton-image {
    height: 45px;
}

@media only screen and (max-width: 768px) {
    .navbar-playbutton {
        position: absolute;
        left: 158px;
    }
    .navbar-playbutton-image {
        height: 36px;
        padding-bottom: 8px;
    }
}

#fade {
    display: none;
    position: fixed;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    background-color: black;
    z-index: 2000;
    -moz-opacity: 0.8;
    opacity: .80;
    filter: alpha(opacity=80);
}

#light {
    display: none;
    position: fixed;
    left: 50%;
    top: 50%;
    -ms-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 2001;
    overflow: visible;
    @media only screen and (max-width: 768px) {
        width: 90vw;
    }

}

#boxclose {
    float: right;
    cursor: pointer;
    color: #fff;
    border: 1px solid #AEAEAE;
    border-radius: 3px;
    background: #222222;
    font-size: 31px;
    font-weight: bold;
    display: inline-block;
    line-height: 0px;
    padding: 11px 3px;
    position: absolute;
    right: 2px;
    top: 2px;
    z-index: 1002;
    opacity: 0.9;
}

.boxclose:before {
    content: "×";
}

#fade:hover ~ #boxclose {
    display: none;
}

#navbar-video-popup {
    width: 100%;
}
