.result {
    position: relative;

    background: $gray-25;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    border-radius: 5px;
    overflow: hidden;

    margin-bottom: $spacing;
    transition: all 300ms ease-in-out;

    > a {
        width: 100%;
    }

    &:hover {
        color: black;
    }

    .result__image {
        width: 100%;
        img {
            width: 100%;
            height: 200px;
            object-fit: cover;
        }
    }

    .result__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: $spacing;
    }

    .result__breadcrump {
        margin-bottom: $spacing;
        opacity: 0.5;
    }

    .result__desc {
        font-size: 25px;
    }

    .result__attr {
        margin-top: $spacing;
        span {
            display: flex;
            width: 100%;
            b {
                margin-right: $spacing;
            }
        }
    }

    .result__actionbar {
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: space-between;
        right: 0;
        height: 100%;
        background-color: rgba($color: #000000, $alpha: 0.04);

        padding: $spacing;

        width: 100%;

        margin-top: $spacing;
        border-radius: 5px;
    }

    .result__count {
        border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
        padding-bottom: $spacing;

        .input-label {
            position: absolute;
            display: block;
            right: 10px;
            margin-top: 7px;
            opacity: 0.5;
        }
    }

    .result__exp {
        display: none;
        opacity: 0.5;
    }

    .result__delete {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        cursor: pointer;

        transition: all 300ms ease-in-out;

        margin-top: $spacing;
        opacity: 0.3;

        label {
            margin: 0;
            cursor: pointer;
        }

        &:hover {
            opacity: 1;
            color: $red;
        }
    }

    &.bookmark {
        min-height: 125px;
    }

    @include media-breakpoint-up(md) {
        flex-wrap: nowrap;

        > a {
            width: auto;
        }

        &.bookmark {
            flex-wrap: wrap;

            .result__content {
                width: calc(100% - 200px);
            }
        }

        .result__container {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .result__delete {
            margin-top: 0;
            label {
                margin-right: $spacing;
            }
        }

        .result__count {
            display: flex;
            align-items: center;
            border: 0;

            padding: 0;

            label {
                margin: 0;
                margin-right: $spacing;
            }
        }

        .result__actionbar {
            margin-top: 0;
            border-radius: 0;
        }

        .result__image {
            width: 150px;
            height: 150px;
            img {
                height: 100%;
            }
        }

        .result__breadcrump {
            margin-bottom: $spacing - 5;
            a {
                font-size: 14px;
            }
        }

        .result__content {
            // padding: $spacing * 2;
            padding: $spacing;
            //padding-top: 0;
        }

        .result__desc {
            font-size: 20px;
        }
    }

    @include media-breakpoint-up(xl) {
        .result__image {
            height: 100%;
            width: 130px;
        }

        .result__actionbar {
            position: absolute;
            width: 250px;

            border-left: 5px solid white;
            border-radius: 0 5px 5px 0;
        }

        .result__count {
            width: 100%;
            flex-direction: column;
            border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
            padding-bottom: $spacing;
            align-items: flex-start;
            label {
                display: none;
                margin-bottom: 5px;
            }
        }

        .result__container {
            width: 100%;
            flex-direction: column;
            align-items: flex-start;
        }

        .result__exp {
            display: block;
        }

        .result__delete {
            margin-top: $spacing;
            display: flex;
            justify-content: space-between;
            width: 100%;
        }
    }

    @include media-breakpoint-down(md) {
        &.bookmark {
            .result__content {
                padding-bottom: 0;
            }
        }

        .result__actionbar {
            margin-top: 0;
        }
    }
}

.result__no {
    font-size: 25px;
    font-weight: 300;
}
