.cms__content {
    display: flex;
    flex-wrap: wrap;

    @extend .col-12;
    padding: $spacer * 2 0 0 0;

    .cms__footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
}

// default
.swiper {
    max-width: calc(100vw - 30px);
    height: 100%;
}

.swiper-wrapper {
    max-height: 100%;
    height: 100%;
    display: flex;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    width: auto;
    flex-shrink: 0;
    display: block;
    height: 100%;
    max-height: 100%;
    border-radius: 8px;
    overflow: hidden;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.swiper {
    width: 100%;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
}

.swiper-slide {
    background-size: cover;
    background-position: center;
}

.mySwiper2 {
    height: 400px;

    @include media-breakpoint-down(md) {
        height: 290px;
    }
}

.mySwiper {
    height: 100px;
    box-sizing: border-box;
    margin: 15px 0;
}

.mySwiper .swiper-slide {
    height: 100%;
    border: 4px solid white;
    cursor: pointer;
}

.mySwiper .swiper-slide-thumb-active {
    opacity: 1;
    border: 3px solid $primary;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
