@use "sass:math";

.dropzone,
.dropzone * {
    box-sizing: border-box;
}
.dropzone {
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: $borderRadius;
    background-color: rgba($color: $primary, $alpha: 0.2);

    border: 1px solid $primary-50;
    margin-top: 25px;

    .dz-preview {
        position: relative;
        display: inline-block;
        width: 120px;
        margin: 0.5em;

        .dz-progress {
            display: block;
            height: 15px;
            border: 1px solid #aaa;
            .dz-upload {
                display: block;
                height: 100%;
                width: 0;
                background: green;
            }
        }

        .dz-error-message {
            color: red;
            display: none;
        }
        &.dz-error {
            .dz-error-message,
            .dz-error-mark {
                display: block;
            }
        }
        &.dz-success {
            .dz-success-mark {
                display: block;
            }
        }

        .dz-error-mark,
        .dz-success-mark {
            position: absolute;
            display: none;
            left: 30px;
            top: 30px;
            width: 54px;
            height: 58px;
            left: 50%;
            margin-left: -(math.div(54px, 2));
        }
    }
}
