/** 
 * version 2
 * @release development
*/

.eprivacy-plugin {
    /* import scss */
    @import 'mixin.scss';
    @import 'variables.scss';
    /* import fonts */
    @import url('../../node_modules/@fortawesome/fontawesome-free/css/all.min.css');

    /* modal styling */
    .epp-wrapper-blur {
        filter: blur(5px);
    }

    &.epp-overflow {
        overflow: hidden;
    }
    .epp-modal {
        position: fixed;
        height: 100%;
        width: 100%;
        background-color: rgba(255, 255, 255, 0.6);
        top: 0;
        left: 0;
        overflow-x: hidden;
        overflow-y: auto;
        z-index: 9999;

        ::-webkit-scrollbar {
            width: 4px;
        }
        ::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            -webkit-border-radius: 10px;
            border-radius: 10px;
        }
        ::-webkit-scrollbar-thumb {
            -webkit-border-radius: 10px;
            border-radius: 10px;
            background: $epp-scrollbar;
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
        }
        ::-webkit-scrollbar-thumb:window-inactive {
            background: $epp-scrollbar;
        }
        .epp-modal-wrapper-bg {
            &.show {
                position: absolute;
                height: 100%;
                width: 100%;
                background-color: $primary;
                z-index: 1;
            }
        }
        .epp-modal-wrapper {
            padding: 20px;
            position: absolute;
            width: 80%;
            height: auto;
            max-height: 90vh;
            overflow-x: hidden;
            //overflow-y: scroll;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: $epp-main-background;
            border-radius: $borderRadius;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* FF3.5+, Opera 9+, Saf1+, Chrome, IE10 */
            .cookie-desc-content {
                overflow: hidden;
                position: absolute;
                top: 0;
                left: 0;
                width: auto;
                padding: 20px 20px 10px;
                text-align: center;
                z-index: 999;
                height: auto;
                min-height: 100%;
                z-index: 999;
                background-color: rgba(255, 255, 255, 1);
                .epp-cookie-content {
                    border-radius: $borderRadius;
                    padding: 20px;
                    margin-bottom: 10px;
                    background: #ececec;
                    // @include epp-primary-color(left);
                    span,
                    p {
                        color: $gray;
                    }
                    span {
                        @include customer-font(var(--epp-font-family), bold, 16px, 22px);
                    }
                    p {
                        @include customer-font(var(--epp-font-family), 100, 16px, 22px);
                        &:last-of-type {
                            margin-bottom: 0;
                        }
                    }
                }
                .cookie-desc-exit {
                    cursor: pointer;
                    background-color: $primary;
                    display: flex;
                    @include epp-round(30px);
                    top: 30px;
                    left: 30px;
                    position: fixed;
                    color: white;
                    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
                    z-index: 222;
                    &::after {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        content: '\f00d';
                        font-family: 'Font Awesome 5 Free';
                        font-weight: 900;
                        @include epp-transition(all, 400ms, ease-in);
                    }
                    &:hover {
                        &::after {
                            transform: translate(-50%, -50%) rotate(90deg);
                        }
                    }
                }
                .cookie-desc-lvlname {
                    display: none;
                    width: 30px;
                    position: fixed;
                    top: 4px;
                    left: 0;
                    line-height: 30px;
                    text-transform: capitalize;
                    background-color: rgba(255, 255, 255, 0.9);

                    font-size: 0;
                    height: 30px;
                    padding: 0;
                    line-height: 30px;
                    top: 70px;
                    left: 30px;
                    border-radius: 10px;
                    &::first-letter {
                        visibility: visible;
                        color: var(--epp-color-secondary);
                        font-size: 14px;
                        font-weight: bold;
                    }
                }
            }
            .epp-modal-header {
                display: flex;
                margin-bottom: 10px;
                h3 {
                    margin: 0;
                    display: flex;
                    align-items: center;
                    margin-top: 10px;
                    @include customer-font(var(--epp-font-family), 400, 21px, 1);
                    @include customHeadlineColor($epp-headline-color);
                    color: $primary;
                    text-transform: uppercase;
                }
                &::before {
                    //content: '\f564';
                    content: '';
                    display: none;
                    position: absolute;
                    height: 40px;
                    width: 40px;
                    border-radius: 40px;
                    @include epp-primary-color(right);
                    top: 20px;
                    font-family: 'Font Awesome 5 Free';
                    font-weight: 900;
                    //@include epp-flex-center(true);
                    color: #e8e8e8;
                    font-size: 26px;
                }
            }
            .epp-modal-description {
                p {
                    font-weight: 100;
                    margin: 0;
                    margin-bottom: 20px;
                    @include customer-font(var(--epp-font-family), 400, 16px, 25px);
                    color: $gray;
                }
            }
            .epp-modal-content {
                margin-bottom: 24px;
                .epp-content-checkbox {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    margin-bottom: 10px;
                    background-color: #f1f1f1;
                    padding: 10px 15px 10px;
                    border-radius: $borderRadius;
                    max-width: auto;
                    label {
                        /* flex-direction: row-reverse;
                        justify-content: flex-end;
                        width: auto; */
                        margin: 0;
                        width: auto;
                        input {
                            opacity: 0;
                            display: none;
                            &:checked {
                                + .epp-checkbox-checkmark {
                                    @include customCheckboxColor($primary);
                                    &::after {
                                        content: '\f00c';
                                        position: absolute;
                                        font-family: 'Font Awesome 5 Free';
                                        font-weight: 900;
                                        font-size: 9px;
                                        color: #e8e8e8;
                                        top: 50%;
                                        left: 50%;
                                        transform: translate(-50%, -50%);
                                    }
                                }
                            }
                            &:disabled {
                                + .epp-checkbox-checkmark {
                                    cursor: default;
                                    background-color: $gray-50;
                                    border-color: $gray-50;
                                    opacity: 0.5;
                                }
                            }
                        }
                        .checked-span {
                            &::after {
                                content: '\f00c';
                                position: absolute;
                                font-family: 'Font Awesome 5 Free';
                                font-weight: 900;
                                font-size: 9px;
                                color: #e8e8e8;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                            }
                            &.epp-checkbox-checkmark {
                                background-color: #e8e8e8;
                            }
                        }
                    }
                    .epp-checkbox-checkmark {
                        /* @include epp-flex-center(true); */
                        display: block;
                        position: relative;
                        height: 20px;
                        width: 20px;
                        border-radius: $borderRadius;
                        cursor: pointer;

                        margin-right: 15px;
                        border: 2px solid $gray-50;
                    }

                    .epp-content-toggle {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        width: 100%;
                        cursor: pointer;
                        p {
                            margin: 0;
                            //margin-top: 2px;
                            text-transform: capitalize;
                            float: left;
                            @include customer-font(var(--epp-font-family), 400, 16px, 1);
                            // padding-top: 3px;
                            padding-top: 0;
                            color: $gray;
                        }
                        .epp-cookie-desc-toggle {
                            cursor: pointer;
                            position: relative;
                            @include epp-round(20px);
                            top: 0;
                            background-color: #e8e8e8;
                            float: right;
                            &::after {
                                content: '\f078';
                                position: absolute;
                                font-family: 'Font Awesome 5 Free';
                                font-weight: 900;
                                font-size: 10px;
                                color: $gray;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                            }
                        }
                        .epp-toggle-up {
                            &.epp-cookie-desc-toggle {
                                &::after {
                                    content: '\f077';
                                }
                            }
                        }
                    }
                }
                .epp-content-cookie-desc {
                    background-color: rgba($color: var(--epp-color-gray), $alpha: 0.1);
                    margin-bottom: 10px;
                    border-radius: $borderRadius;
                    font-size: 14px;
                    z-index: 3;
                    //padding-bottom: 10px;
                    > p {
                        &:first-of-type {
                            padding: 15px;
                            margin: 0;
                            @include customer-font(var(--epp-font-family), 400, 16px, 25px);
                            color: $gray;
                        }
                    }
                    .epp-cookie-details {
                        position: relative;
                        min-height: 30px;
                        bottom: 5px;
                        margin-left: 5px;
                        margin-right: 5px;
                        border-radius: $borderRadius;
                        color: #e8e8e8;
                        /* overflow-y: auto;
                        overflow-x: hidden; */
                        //background-color: $epp-color-third;
                        //@include epp-flex-center(true);
                        text-align: center;
                        cursor: pointer;
                        padding: 0px 10px;
                        border: none;
                        .epp-cookie-detail-content {
                            margin-top: 10px;
                            overflow-y: auto;
                            overflow-x: hidden;
                            max-height: 211px;
                        }
                        .epp-cookie-content {
                            //margin-top: 10px !important;
                            margin-bottom: 10px;
                            background-color: #00579e;
                            border-radius: $borderRadius;

                            padding: 10px 10px 1px 10px;

                            span {
                                //font-weight: bold;
                                color: var(--epp-color-light);
                                @include customer-font(var(--epp-font-family), 100, 16px, 22px);
                            }
                            p {
                                //font-weight: bold;
                                margin: 0;
                                margin-bottom: 10px;
                                color: var(--epp-color-light);
                                @include customer-font(var(--epp-font-family), bold, 16px, 22px);
                            }
                            &:last-of-type {
                                margin-bottom: 0;
                            }
                        }
                        .epp-detail-titel {
                            display: block;
                            position: relative;
                            background-color: $primary-75;
                            color: #e8e8e8;
                            border-radius: $borderRadius;
                            //height: 30px;
                            padding: 7px;
                            @include customer-font(var(--epp-font-family), 300, 14px, 22px);
                            transition: all 500ms ease;
                            &:hover {
                                background: $primary-50;
                                color: #ffffff;
                            }
                        }
                    }
                    &.epp-show {
                        opacity: 1 !important;
                        display: block !important;
                        @include epp-transition(opacity, 0.4s, ease);
                    }
                }
            }

            .epp-modal-buttons {
                display: flex;
                flex-direction: column;
                margin-bottom: 20px;
                button {
                    &.epp-button {
                        overflow: hidden;
                        position: relative;
                        cursor: pointer;
                        border: none;
                        color: #e8e8e8;
                        border-radius: $borderRadius;
                        text-align: center;

                        font-size: 18px;
                        padding: 14px 0 16px 0;
                        &:focus {
                            outline: none;
                        }
                        &.epp-primary {
                            color: $color-white;
                            @include btn-primary();
                            @include customer-font(var(--epp-font-family), 300, 16px, 1);
                            border-radius: $borderRadius;
                            // padding: 15px;
                            // height: 41px;
                        }
                        &.epp-secondary {
                            margin-top: 10px;
                            // color: #7d7d7d;
                            @include btn-secondary();
                            opacity: 1;
                            @include customer-font(var(--epp-font-family), 300, 16px, 1);
                            border-radius: $borderRadius;
                            // padding: 15px;
                            // height: 41px;
                        }
                    }
                }
                &::before {
                    content: '';
                    position: relative;
                    height: 2px;
                    width: 66%;
                    background-color: var(--epp-color-gray);
                    top: -11px;
                    align-self: center;
                }
            }

            .epp-modal-info {
                @include epp-flex-center();
                a {
                    text-decoration: none;
                    @include customer-font(var(--epp-font-family), 100, 12px, 1);
                    color: $gray;
                    margin: 0 5px;
                    &:nth-of-type(2) {
                        &::before {
                            content: '|';
                            display: inline-block;
                            margin: 0 5px 0 -5px;
                        }
                    }
                }
            }
            @media screen and (min-width: 425px) {
                max-width: 400px;
            }
            @media screen and (min-width: 768px) {
                max-width: 400px;
            }
            @media screen and (min-width: 992px) {
                max-height: 100vh;
                overflow-x: hidden;
                overflow-y: visible;
            }
        }
        h3 {
            font-weight: 400;
        }

        p {
            color: $epp-color-p;
        }

        @media screen and (max-width: 500px) {
            .epp-modal-description {
                p {
                    text-align: center;
                }
            }
            .epp-content-cookie-desc {
                p {
                    text-align: center;
                }
            }
            .epp-content-toggle {
                p {
                    position: relative;
                    left: 50%;
                    transform: translate(calc(-50% + -15px));
                }
            }
        }
    }
    /* change-cookie styling */
    .epp-cookie {
        display: flex;
        flex-direction: row-reverse;
        align-items: flex-end;
        position: fixed;
        right: -100px;
        //bottom: 25px;
        width: auto;
        z-index: 999;
        &.epp-cookie-left {
            left: -100px;
            right: auto;
            flex-direction: row;
            .epp-cookie-wrapper {
                flex-direction: row-reverse;
                margin-right: 0;
                margin-left: 5px;
                padding-right: 10px;
                padding: 5px;
                .epp-btn-group {
                    &::before {
                        display: none !important;
                    }
                    &::after {
                        content: '';
                        display: none;
                        position: relative;
                        height: 20px;
                        width: 2px;
                        background-color: #ece9e6;
                        margin-right: 10px;
                        margin-top: 10px;
                    }
                    .epp-cookie-button {
                        margin-left: 0;
                        margin-right: 0;
                    }
                }
                @media screen and (min-width: 400px) {
                    padding-right: 20px;
                    .epp-btn-group {
                        &::after {
                            display: block;
                        }
                        .epp-cookie-button {
                            margin-right: 10px;
                        }
                    }
                }
            }
        }
        // muss gleiche höhe sein wie der button
        height: 50px;
        .epp-cookie-icon {
            cursor: pointer;
            @include epp-flex-center(true);
            // button höhe
            border-radius: $borderRadius;
            height: 48px;
            width: 48px;
            @include customCookieIconColor($custom-cookie-icon-color);
            @include epp-transition(all, 500ms, ease-in-out);
            &::before {
                content: '\f564';
                position: absolute;
                font-family: 'Font Awesome 5 Free';
                font-weight: 900;
                font-size: 24px;
                color: #e8e8e8;
            }
            &.hovered {
                border-radius: 5px;
            }
            @media screen and (min-width: 500px) {
                &:hover {
                    border-radius: 5px;
                }
            }
        }

        .epp-cookie-wrapper {
            position: relative;
            width: auto;
            max-width: 300px;
            height: 48px;
            background-color: #e8e8e8;
            border-radius: $borderRadius;
            margin-right: 5px;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 5px;
            padding-left: 10px;
            box-shadow: 0 0 5px rgba(204, 204, 204, 0.5);

            // &::after {
            //     left: 100%;
            //     top: 50%;
            //     border: solid transparent;
            //     content: " ";
            //     height: 0;
            //     width: 0;
            //     position: absolute;
            //     pointer-events: none;
            //     border-color: rgba(255, 255, 255, 0);
            //     border-left-color:#e8e8e8;
            //     border-width: 10px;
            //     transform: translate(0, -50%);
            // }
            .epp-cookie-text {
                display: none;
                position: relative;
                width: 100%;
                margin-left: 5px;
                @include customer-font(var(--epp-font-family), 100, 14px, 1);
                padding-top: 0;
                p {
                    margin: 0;
                    opacity: 0.7;
                    font-size: 12px;
                    color: #949494;
                    line-height: 1;
                }
                &::after {
                    display: none;
                    content: '';
                    position: absolute;
                    height: 100%;
                    width: 2px;
                    background: $epp-color-gray;
                    top: 0;
                    right: -3px;
                }
            }
            .epp-btn-group {
                display: flex;
                //margin-top: 10px;
                .epp-cookie-button {
                    display: flex !important;
                    position: relative;
                    overflow: hidden;
                    cursor: pointer;
                    padding: 12px 15px;
                    border: none;
                    @include epp-flex-center(true);
                    @include epp-round(40px);
                    border-radius: $borderRadius;
                    margin-left: 0;
                    width: 40px !important;

                    &:focus {
                        outline: none;
                    }

                    &:hover {
                        &::after {
                            color: $gray;
                        }
                    }

                    &::after {
                        content: '\f013';
                        position: absolute;
                        font-family: 'Font Awesome 5 Free';
                        font-weight: 900;
                        font-size: 18px;
                        color: rgba(0, 0, 0, 0.4);
                        transition: all 500ms ease;
                    }
                    @include btn-secondary();
                }
                &::before {
                    display: none;
                    content: '';
                    position: relative;
                    height: 20px;
                    width: 2px;
                    background-color: $gray-25;
                    margin-left: 10px;
                    margin-top: 10px;
                }
            }
            .epp-blur {
                -webkit-filter: blur(10px);
                -moz-filter: blur(10px);
                -o-filter: blur(10px);
                -ms-filter: blur(10px);
                filter: blur(10px);
            }
        }
        h3 {
            font-weight: 400;
        }
        p {
            color: $epp-color-p;
        }
        // media queries
        @media screen and (min-width: 400px) {
            .epp-cookie-wrapper {
                .epp-cookie-text {
                    display: block;
                }
                .epp-btn-group {
                    &::before {
                        display: block;
                    }
                    .epp-cookie-button {
                        margin-left: 10px;
                    }
                }
            }
        }
        @media screen and (min-width: 568px) {
            .epp-cookie-wrapper {
                max-width: 500px;
            }
        }
        @media screen and (min-width: 768px) {
            .epp-cookie-wrapper {
                max-width: 600px;
                .epp-cookie-text {
                    p {
                        font-size: 14px;
                    }
                }
            }
        }
        @media screen and (min-width: 992px) {
        }
    }
    .eprivacy-settings-btn {
        display: inline-block;
        font-family: inherit;
        font-size: inherit;
        padding: 0.7em 1em;
        text-decoration: none;
        transition: background-color 300ms ease-in-out;
        border-radius: $borderRadius;
        background-color: $primary;
        color: #e8e8e8;
        border: none;
        cursor: pointer;
        &:hover {
            background-color: rgba($color: $primary, $alpha: 0.7);
        }
        &:focus {
            outline: none;
            border: none;
        }
    }

    .epp-consent {
        display: flex;
        position: fixed;
        //width: 90%;
        bottom: 0px;
        left: 50%;
        transform: translate(-50%);
        //border-radius: 10px;
        padding: 20px 20px;
        //bottom: 30px;
        @include epp-primary-color-image(left, url('/img/wave.png'));
        width: 100%;
        border-radius: 10px 10px 0 0;

        bottom: 0;
        .epp-consent-content {
            display: flex;
            //flex-direction: row;
            flex-direction: column;
            justify-content: space-between;
            .epp-consent-disclaimer {
                font-size: 16px;
                color: #e8e8e8;
                //width: 75%;
                width: 100%;
                margin-bottom: 20px;
                p {
                    top: 50%;
                    position: relative;
                    transform: translate(0, -50%);
                    margin: 0;
                    font-family: 'Roboto', sans-serif;
                    font-weight: 300;
                }
            }
            .epp-consent-button {
                display: flex;
                flex-direction: row;
                //justify-content: center;
                justify-content: space-between;
                //justify-content: flex-end;
                //width: 25%;
                width: 100%;
                button {
                    position: relative;
                    top: 50%;
                    transform: translate(0, -50%);
                    &.epp-button {
                        overflow: hidden;
                        position: relative;
                        cursor: pointer;
                        height: 40px;
                        //padding: 0 20px;
                        border: none;
                        color: #e8e8e8;
                        font-size: 16px;
                        border-radius: $borderRadius;
                        text-align: center;
                        &:focus {
                            outline: none;
                        }
                        &.epp-btn-consent {
                            justify-content: center;
                            @include btn-white();
                            left: 0px;
                            padding: 0;
                            height: 40px !important;
                            padding: 0;
                            width: 180px;
                            color: var(--epp-color-secondary);
                        }
                        &.epp-settings {
                            margin-left: 20px;
                            height: 40px;
                            width: 40px;
                            background-color: #e8e8e8;
                            i {
                                color: var(--epp-color-secondary);
                                @include epp-transition(all, 400ms, ease-in);
                            }
                            &:hover {
                                i {
                                    transform: rotate(90deg);
                                }
                            }
                        }
                    }
                }
            }
        }
        @media screen and (min-width: 992px) {
            max-width: 992px;
            bottom: 30px;
            border-radius: $borderRadius;
            .epp-consent-content {
                flex-direction: row;
                .epp-consent-disclaimer {
                    width: 73%;
                    margin-bottom: 0;
                }
                .epp-consent-button {
                    width: 25%;
                }
            }
        }
    }
}
