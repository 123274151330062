.sortable {
    width: 100%;

    .sortable__item--empty {
        cursor: grab;
    }

    .sortable__item--drag {
        opacity: 0.3;
        cursor: grabbing;
    }
}
