.dataTables_scrollHead {
    padding-bottom: $spacing;
}
table {
    tr {
        &.odd,
        &.even {
            &:hover {
                background-color: rgba($color: $primary, $alpha: 0.5);
            }
        }
    }
    .clickable {
        cursor: pointer;
    }
}
