form {
    .form-group {
        position: relative;
    }

    label {
        &.error {
            position: relative;
            background: $red;
            top: -3px;
            height: 36px;
            margin: 0;
            right: 0;
            border-radius: 0 0 3px 3px;

            padding: 0 15px;
            color: white;

            align-items: center;
            display: flex;

            font-size: 14px;
            justify-content: center;
        }
    }

    input,
    textarea {
        &.error {
            border-color: $red;
        }

        &.valid {
            border-color: $green;
        }
    }

    @include media-breakpoint-up(md) {
        label {
            &.error {
                position: absolute;
                top: 33px;
                border-radius: 3px;
            }
            &#message-error {
                &.error {
                    background: transparent;
                    color: $red;
                }
            }
        }
    }
}

.modal {
    .modal-content {
        border-radius: $borderRadius;
        border: 0;

        .modal-header {
            background-color: $gray-25;
            border-radius: 0;
        }
    }
}

.bookmark_dataprivacy_button {
    margin-right: auto !important;
}

.required-field {
    margin-top: 1rem;
}
