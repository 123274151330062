// Fonts
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;800&display=swap');

@import './utilities/colors';
@import './customer/colors';

// Vendor import
@import '~bootstrap/scss/bootstrap';
@import '~datatables.net-bs4/css/dataTables.bootstrap4';
@import '~lightslider/src/css/lightslider';
@import '~ekko-lightbox/dist/ekko-lightbox';
@import '~datatables.net-responsive-bs4/css/responsive.bootstrap4';
@import '~dropzone/dist/dropzone.css';
@import '~jquery-bar-rating/dist/themes/bootstrap-stars.css';

// utilities import
@import './utilities/variables';
@import './utilities/mixins';

@import './vendor/eprivacy/eprivacy';

// Base styling
@import './base/defaults';

// layout styling
@import './layout/header';
@import './layout/nav';
@import './layout/megamenu';
@import './layout/footer';
@import './layout/filter';

@import './layout/slider';
@import './layout/category';
@import './layout/products';
@import './layout/result';
@import './layout/searchbar';
@import './layout/dropzone';
@import './layout/contact';
@import './layout/cms';
@import './layout/table';
@import './layout/sortable';

// components styling

// customer
@import './customer/variables_customers';
@import './customer/customers';

.tab-content {
    width: 100% !important;
}

.br-theme-bootstrap-stars .br-widget a:after {
    content: '\f005';
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    color: #d2d2d2;
}
.br-theme-bootstrap-stars .br-widget a.br-active:after {
    color: $primary;
}
.br-theme-bootstrap-stars .br-widget a.br-selected:after {
    color: $primary;
}

table tr {
    &.odd {
        background-color: transparent;
    }

    &.even {
        background-color: $gray-25;
    }
}

.products tr,
.details tr {
    transition: 0.5s;

    &:hover {
        background-color: $gray-50;
    }

    &.active {
        background-color: $primary-75 !important;
    }
}

.datatable {
    td {
        @media (max-width: 768px) {
            a {
                display: inline;
                i {
                    font-size: 26px;
                    margin: 12px;
                }
            }
        }
    }
}

.datatable > tbody > tr > td {
    text-align: center;
}

// first child
.datatable > tbody > tr > td:first-child {
    text-align: left;
}

.epp-cookie {
    @media (max-width: 500px) {
        display: none !important;
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type='number'] {
    -moz-appearance: textfield; /* Firefox */
}
input[type='number'] {
    -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
