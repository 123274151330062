.megamenu {
    position: relative;
    background: white;

    .megamenu__item {
        z-index: 101;

        .megamenu__item-container {
            position: relative;

            border: 0;
            background: $fallback;
            border-radius: $borderRadius;
            min-height: 200px;
            max-height: 200px;

            cursor: pointer;
            overflow: hidden;

            .megamenu__item-title {
                position: absolute;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 50px;
                width: 100%;

                bottom: 0;
                background-color: #e7e6e6;

                border-top: 4px solid white;

                a {
                    margin: 0;
                    color: black;
                    text-decoration: none;
                    text-transform: none;
                    font-weight: bold;
                    text-align: center;

                    font-size: 16px;

                    &:hover,
                    &:active,
                    &:visited,
                    &:focus {
                        text-decoration: none;
                        text-transform: none;
                        color: black;
                    }

                    @include media-breakpoint-down(md) {
                        font-size: 14px;
                    }
                }
            }

            img {
                height: 150px;
                display: block;
                object-fit: cover;
                min-height: 100px;

                border-radius: 0;

                transition: all 100ms ease-in-out;
            }

            &::after {
                content: '';
                position: absolute;
                height: 100%;
                width: 100%;

                border: 4px solid transparent;
                transition: all 200ms ease-in-out;
                border-radius: 7px;
            }

            &.active-item {
                &::after {
                    border-color: $primary;
                }
            }

            &:hover {
                &::after {
                    border-color: $primary;
                }

                img {
                    transform: scale(1.1);
                }
            }
        }

        &.megamenu__item--short {
            padding-top: 5px !important;
            padding-bottom: 30px !important;
            margin-top: 0 !important;

            .megamenu__item-container {
                height: 50px;
                min-height: 50px;
                max-height: 50px;

                .megamenu__item-title {
                    border: 0;
                }
            }
        }

        &::after {
            opacity: 0;
            display: inline-block;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 20px 20px 20px;
            border-color: transparent transparent $primary transparent;

            content: '';

            position: absolute;
            bottom: 0;

            left: 50%;
            transform: translate(-50%, -190px);
            z-index: -1;

            transition: all 200ms ease-in-out;

            @include media-breakpoint-down(lg) {
                display: none;
            }
        }

        &.active {
            &::after {
                opacity: 1;
                transform: translate(-50%, 15px);
            }
        }
    }

    .megamenu__nav {
        opacity: 0;
        position: absolute;
        background-color: $primary;

        border-radius: $borderRadius-nav;

        padding: 20px;
        top: calc(100% - 0px);
        z-index: 100;
        transform: translate(0, -190px);

        width: calc(100% - 30px);
        left: 15px;

        pointer-events: none;

        transition: all 200ms ease-in-out;

        .close {
            @media (min-width: 757px) {
                display: none;
            }

            i {
                color: #fff;
            }
        }

        &.open {
            opacity: 1;
            transform: translate(0, 14px);
            pointer-events: all;
            z-index: 45; // Ansonsten ist der Slide über dem Megamenue
        }

        .megamenu__nav-content {
            ul {
                list-style: none;
                margin: 0;
                padding: 0;

                li {
                    &.megamenu__nav-item {
                        margin: 5px 0;

                        a {
                            font-weight: 400;
                            font-size: 15px;

                            overflow-x: hidden;
                            word-wrap: break-word;
                            hyphens: auto;
                            transition: all 400ms ease-in-out;
                        }

                        // &:hover {
                        //     a {
                        //         text-shadow: 0 0 0.65px white, 0 0 0.65px white;
                        //         transition: all 0.5s;
                        //     }
                        // }
                    }
                }
            }
        }

        .row {
            display: grid;
            grid-template-columns: auto;
            grid-template-rows: repeat(var(--number-of-rows), auto);
            padding-left: 15px;
            padding-right: 15px;
            grid-auto-flow: column;

            //max-height: calc((var(--number-of-childs) / 4) * 38px);
            //
            //@include media-breakpoint-down(lg) {
            //    max-height: calc((var(--number-of-childs) / 2) * 38px);
            //}
            ////
            @include media-breakpoint-down(sm) {
                grid-template-rows: repeat(var(--number-of-rows-s), auto);
            }
        }
    }

    @include media-breakpoint-up(lg) {
        .megamenu__item {
            &::after {
                transform: translate(-50%, -220px);
            }

            &.active {
                &::after {
                    transform: translate(-50%, 0);
                }
            }
        }

        .megamenu__nav {
            transform: translate(0, -220px);

            &.open {
                transform: translate(0, 0);
            }
        }
    }
}
