.downloads {
    display: flex;
    align-items: center;
    position: relative;

    background: $gray-25;
    padding: 10px $spacing;
    border-radius: 5px;

    margin-bottom: $spacing;

    transition: all 300ms ease-in-out;

    &::after {
        position: absolute;
        right: $spacing;

        font-family: 'Font Awesome 5 Free';
        font-weight: 400;
        content: '\f019';
    }

    span {
        margin-left: $spacing;
    }
}

@media print {
    @page {
        margin-top: 0;
        margin-bottom: 0;
    }
    body {
        padding-top: 72px;
        padding-bottom: 72px;
    }
}

.print-date {
    position: absolute;
    top: 40px;
    right: 0px;
    width: 200px;
}

.print {
    .row {
        margin: 0 15px !important;
        display: block;
    }

    .product-slider,
    .product-content {
        width: 49% !important;
        float: left;
        display: inline-block;
    }

    ul,
    li {
        list-style: none;
    }

    .print-icon,
    .print-icon__details,
    .bookmarks_remove,
    .bookmarks_add {
        display: none;
    }
}

.print-icon {
    display: none;

    &__details {
        display: inline block;
        position: absolute;
        right: 0px;
        top: 0px;

        i {
            font-size: 24px;
        }

        &:hover {
            cursor: pointer;
        }
    }
}

.product-slider {
    margin-bottom: $spacing * 2;

    .lightSlider.lsGrab > * {
        // height: 30px;
        a {
            img {
                border-radius: $borderRadius;
                min-width: 100%;
            }
        }
    }

    /* .lSAction {
        a {
            background-image: url('');
        }
    } */

    .img-fluid {
        min-width: 100% !important;
    }

    .lSPager {
        display: flex;
        height: 75px;
        width: 100% !important;

        li {
            height: 75px !important;
            width: 75px !important;

            border-radius: $borderRadius;

            &.active {
                border-radius: $borderRadius !important;
                border: 2px solid $gray;
            }

            &:hover {
                border-radius: $borderRadius !important;
            }
        }
    }

    @include media-breakpoint-up(md) {
        margin-bottom: 0;
    }
}

.detail-info__refresh {
    display: flex;
    margin-bottom: $spacing - 5px;

    a {
        background: $gray-25;
    }

    span {
        color: white;
    }

    .detail-info__content {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        padding: $spacing;
        border-radius: 5px;

        background-color: $primary;
    }
}

.ekko-lightbox {

    .modal-content {
        flex-direction: column-reverse;

        .modal-header {
            background-color: white !important;

            .close {
                display: none;
            }

            .modal-title {
                font-size: 1rem;
            }
        }
    }
}

#product_picture_text {
    position: absolute;
    bottom: 0px;
}
